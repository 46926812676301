var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { T as Types } from "./requests.js";
import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { staticClass: "dlg-print-sample-processing", attrs: { "ok-text": "Print", "title": "Print Sample Processing", "after-close": _vm.afterModalClose, "width": 520 }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.cancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "type": "primary", "loading": _vm.isLoading }, on: { "click": function($event) {
        return handleSubmit(_vm.onPrint);
      } } }, [_vm._v("Print")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("cf-select-input", { attrs: { "form-item": "", "reference": "cf.common.label-printers", "source": "id", "source-label": "name", "label": "Label Printer", "rules": "required", "all-in-value": "", "default-active-first-option": true, "span": 24 }, on: { "change": _vm.onChangePrinter } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "PrintSample",
  inject: ["resourceProps"],
  data() {
    const [printerContainerProdutionProps, sampleProps] = this.resourceProps;
    return {
      visible: false,
      printForm: {},
      printerContainerProdutionProps,
      sampleProps
    };
  },
  computed: {
    isLoading() {
      const { namespace, REQUESTS_IS_LOADING } = Types;
      return this.$store.getters[`${namespace}/${REQUESTS_IS_LOADING}`];
    },
    entity() {
      return this.sampleProps.crud.getEntity();
    },
    customRedirectRoute() {
      return `/container-filling/sample-processing/${this.entity.id}`;
    }
  },
  async mounted() {
    if (!this.entity) {
      await this.sampleProps.crud.fetchEntity();
    }
    this.visible = true;
    this.storeValue("printed", "Y");
    this.storeValue("containerId", this.entity.id);
    this.storeValue("sampleId", this.entity.currentSampleId);
  },
  methods: {
    cancel() {
      this.visible = false;
    },
    afterModalClose() {
      this.$router.push(this.customRedirectRoute);
    },
    storeValue(resourceKey, value) {
      const printForm = this.printForm;
      this.printForm = __spreadProps(__spreadValues({}, printForm), {
        [resourceKey]: value
      });
      this.printerContainerProdutionProps.crud.updateEntity("printForm", {
        resourceKey,
        value
      });
    },
    onChangePrinter(value) {
      this.storeValue("labelPrinterId", value.id);
    },
    onPrint() {
      this.printerContainerProdutionProps.crud.submitEntity("print").then(() => {
        this.afterModalClose();
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var PrintSampleProcessing = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "print-sample-processing" }, [_c("resource", { attrs: { "name": "cf.common.label-printers", "api-url": _vm.apiUrl, "resource-id-name": "name" } }), _c("resource", { attrs: { "name": ["cf.container-prints", "cf.samples"], "api-url": _vm.apiUrl, "redirect-route": ["/container-filling/container-processing"] } }, [_c("print-sample-processing")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    PrintSampleProcessing
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
